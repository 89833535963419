import React, { useEffect } from "react";

const Contact = () => {
  useEffect(() => {
    document.title = "Contact | PartexAeromarine Logistics Ltd";
  }, []);
  return (
    <div>
      <div
        style={{
          backgroundImage:
            "url(img_upload/page_2016_07_23_12_30_42_bg_for_parallax.jpg)",
          backgroundColor: "#001122",
        }}
        className="paralax bg-image page-title">
        <div className="container-fluid">
          <h1 className="title">Contact</h1>
          <span className="subtitle"></span>
        </div>
      </div>

      <div id="contentxx" className="contentxx">
        <div className="container">
          <div className="row">
            <div className="col-xs-12  col-sm-12 col-md-12  ">
              <section className="main-content" role="main">
                <div
                  className="vc_row wpb_row vc_row-fluid vc_custom_1448311533992 vc_row-no-padding"
                  data-vc-stretch-content="true"
                  data-vc-full-width-init="true"
                  data-vc-full-width="true"
                  style={{
                    paddingTop: "0px !important",
                    position: "relative",
                    left: "-89.5px",
                    boxSizing: "border-box",
                    width: "1349px",
                  }}>
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner ">
                      <div className="wpb_wrapper">
                        <div className="wpb_gmaps_widget wpb_content_element">
                          <div className="wpb_wrapper">
                            <div className="wpb_map_wraper">
                              <iframe
                                width="600"
                                height="450"
                                frameborder="0"
                                allowfullscreen=""
                                style={{
                                  border: "0px none",
                                  pointerEvents: "none",
                                }}
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.3595132969826!2d90.4063238!3d23.77020890000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c77bbf03a267%3A0x770037bad66f3f7!2sShanta+Western+Tower%2C+Tejgaon+-+Gulshan+Link+Rd!5e0!3m2!1sen!2sbd!4v1400309186215&amp;t=m&amp;z=14&amp;output=embed"></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="vc_row wpb_row vc_row-fluid ">
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner ">
                      <div className="wpb_wrapper">
                        <div className="text-left  fobox ">
                          <h4>SEND US A MESSAGE</h4>
                        </div>
                        <div>
                          <div
                            lang="en-US"
                            dir="ltr"
                            id="wpcf7-f1760-p1712-o1"
                            className="wpcf7"
                            role="form">
                            <div className="screen-reader-response"></div>
                            <form
                              novalidate="novalidate"
                              className="wpcf7-form"
                              method="post"
                              action="#">
                              <input
                                type="hidden"
                                name="from_where"
                                value="contact"
                              />
                              <div style={{ display: "none" }}>
                                <input
                                  type="hidden"
                                  value="1760"
                                  name="_wpcf7"
                                />
                              </div>
                              <div
                                className="reply-form form-inline"
                                id="contactForm">
                                <div className="default-inp form-elem">
                                  <span className="wpcf7-form-control-wrap your-subject">
                                    <input
                                      type="text"
                                      placeholder="Your Name"
                                      aria-invalid="false"
                                      className="wpcf7-form-control wpcf7-text"
                                      size="40"
                                      value=""
                                      name="txtname"
                                    />
                                  </span>
                                </div>
                                <div className="default-inp form-elem">
                                  <span className="wpcf7-form-control-wrap your-subject">
                                    <input
                                      type="text"
                                      placeholder="Email"
                                      aria-invalid="false"
                                      className="wpcf7-form-control wpcf7-text"
                                      size="40"
                                      value=""
                                      name="txtemail"
                                    />
                                  </span>
                                </div>
                                <div className="default-inp form-elem">
                                  <span className="wpcf7-form-control-wrap your-subject">
                                    <input
                                      type="text"
                                      placeholder="Contact Number"
                                      aria-invalid="false"
                                      className="wpcf7-form-control wpcf7-text"
                                      size="40"
                                      value=""
                                      name="txtcontactno"
                                    />
                                  </span>
                                </div>
                                <div className="form-elem default-inp">
                                  <span className="wpcf7-form-control-wrap your-message">
                                    <textarea
                                      placeholder="Your Message"
                                      aria-invalid="false"
                                      className="wpcf7-form-control wpcf7-textarea"
                                      rows="10"
                                      cols="40"
                                      name="txtmessage"></textarea>
                                  </span>
                                </div>
                                <div className="form-elem">
                                  <input
                                    type="submit"
                                    className="wpcf7-form-control wpcf7-submit"
                                    value="Send"
                                  />
                                </div>
                              </div>
                              <div className="wpcf7-response-output wpcf7-display-none"></div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />
                  <br />
                  <br />
                  <br />
                </div>

                <div style={{ height: "150px" }}>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>

                <div className="wpb_column vc_column_container vc_col-sm-6">
                  <div className="vc_column-inner ">
                    <div className="wpb_wrapper">
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div className="text-left  fobox ">
                                <h2>Corporate Office</h2>

                                <h4>PartexAeromarine Logistics Ltd </h4>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-location-arrow"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p>
                                    Shanta Western Tower, Level-13,&nbsp;
                                    <br />
                                    Bir Uttam Mir Shawkat Road
                                    <br />
                                    186,Tejgaon I/A (Gulshan Link Road)&nbsp;
                                    <br />
                                    Dhaka &ndash; 1208, Bangladesh
                                  </p>{" "}
                                </div>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-phone"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p> +88 02 887 8815</p>
                                </div>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-fax"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p> +88 02 887 8815</p>
                                </div>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-envelope"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p> info@pambd.com, shah.alam@pambd.com</p>
                                </div>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-clock-o"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p>
                                    {" "}
                                    Sun - Thu 09:00 AM - 05:00 PM BST (GMT + 6)
                                  </p>
                                </div>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-internet-explorer"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p> www.pambd.com</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div className="text-left  fobox ">
                                <h4>Operation Office </h4>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-location-arrow"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p>
                                    F Karim, 68, Tejgaon, I/A, Dhaka &ndash;
                                    1208, Bangladesh
                                  </p>{" "}
                                </div>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-phone"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p> +88 02 887 8815</p>
                                </div>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-fax"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p> +88 02 887 8185</p>
                                </div>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-envelope"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p> info@pambd.com</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div className="text-left  fobox ">
                                <h4>Dhaka Airport Office </h4>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-location-arrow"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p>
                                    House No: 22, Road No: 27,&nbsp;
                                    <br />
                                    Sector: 07, Uttara Model Town,&nbsp;
                                    <br />
                                    Dhaka 1230, Bangladesh
                                  </p>{" "}
                                </div>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-phone"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p> +88 02 887 8185</p>
                                </div>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-fax"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p> +88 02 887 8185</p>
                                </div>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-envelope"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p> info@pambd.com</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div className="text-left  fobox ">
                                <h4>Chittagong Office Address </h4>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-location-arrow"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p>
                                    Ayub Trade Center (10th Floor)
                                    <br />
                                    1269/B, Sheikh Mujib Road,Agrabad C/A
                                    <br />
                                    Chittagong 4100, Bangladesh
                                  </p>{" "}
                                </div>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-phone"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p> +88 031 252 4464</p>
                                </div>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-envelope"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p> info@pambd.com</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>

                <div className="wpb_column vc_column_container vc_col-sm-6">
                  <div className="vc_column-inner ">
                    <div className="wpb_wrapper">
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div className="text-left  fobox ">
                                <h2>Overseas Office</h2>

                                <h4>India Office </h4>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-location-arrow"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p>
                                    PartexAeromarine Logistics (Pvt.) Ltd.
                                    <br />
                                    Sai Enclave, Plot: 33, Ahliya Complex, 2nd
                                    Floor,&nbsp;
                                    <br />
                                    Sector: 23, Dwarka,&nbsp;
                                    <br />
                                    New Delhi- 110023, India.
                                  </p>{" "}
                                </div>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-phone"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p> +918527376180</p>
                                </div>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-envelope"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p> sales@partexaeromarineindia.com</p>
                                </div>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-internet-explorer"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p> sales@partexaeromarineindia.com</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div className="text-left  fobox ">
                                <h4>Singapore Office </h4>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-location-arrow"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p>
                                    3 Church Street
                                    <br />
                                    12-01A Samsung Hub
                                    <br />
                                    Singapore 049483
                                  </p>{" "}
                                </div>
                              </div>

                              <div className="pix_icon_element pix_icon_element_content vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                                <div
                                  style={{ borderColor: "#eeeeee" }}
                                  className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-have-style-inner vc_icon_element-size-sm vc_icon_element-style-boxed-outline vc_icon_element-outline vc_icon_element-background-color-custom">
                                  <span
                                    style={{ color: "#333333 !important" }}
                                    className="vc_icon_element-icon fa fa-phone"></span>
                                </div>
                                <div className="vc_icon_content">
                                  <p> +6582985818</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
