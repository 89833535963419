import React from "react";

import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div>
      <header id="this-is-top">
        <div className="container-fluid">
          <div className="topmenu row">
            <nav className="col-sm-offset-3 col-md-offset-4 col-lg-offset-4 col-md-7">
              <span style={{ color: "white", fontSize: "10.5px" }}>
                Shanta Western Tower, Level-13,&nbsp; Bir Uttam Mir Shawkat Road
                186,Tejgaon I/A (Gulshan Link Road)&nbsp; Dhaka &ndash; 1208,
                Bangladesh
              </span>
            </nav>
            <nav className="text-right col-md-1">
              <Link
                to="https://www.facebook.com/"
                target="_blank"
                rel="nofollow"
                title="Facebook">
                <i className="fa fa-facebook"></i>
              </Link>
              <Link
                to="https://twitter.com/"
                target="_blank"
                rel="nofollow"
                title="Twitter">
                <i className="fa fa-twitter"></i>
              </Link>
              <Link
                to="https://www.linkedin.com/"
                target="_blank"
                rel="nofollow"
                title="Linkedin">
                <i className="fa fa-linkedin"></i>
              </Link>
            </nav>
          </div>
          <div className="row header">
            <div className="col-sm-12 col-md-3 col-lg-3">
              <Link to="/" id="logo">
                <img className="logo__img" src="assets/img/logo.png" />
              </Link>
            </div>
            <div className="col-sm-offset-1 col-md-offset-1 col-lg-offset-1 col-sm-8 col-md-8 col-lg-8">
              <div className="text-right header-padding">
                <div className="col-sm-12 col-md-3">
                  <span>CALL US</span>+88 02 887 8815
                </div>

                <div className="col-sm-12 col-md-3">
                  <span>EMAIL US</span>info@pambd.com, shah.alam@pambd.com
                </div>

                <div className="col-sm-12 col-md-4">
                  <span>WORKING HOURS</span>Sun - Thu 09:00 AM - 05:00 PM BST
                  (GMT + 6)
                </div>

                <div className="col-sm-12 col-md-2">
                  <img src="assets/img/partner.jpg" />
                </div>
                {/* <Link className="btn btn-success" to="#">GET A FREE QUOTE</Link> */}
              </div>
            </div>
          </div>
          <div id="main-menu-bg"></div>
          <Link id="menu-open" to="#">
            <i className="fa fa-bars"></i>
          </Link>
          <nav className="main-menu navbar-main-slide">
            <ul className="nav navbar-nav navbar-main">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="dropdown">
                <Link
                  data-toggle="dropdown"
                  className="dropdown-toggle border-hover-color1"
                  to="#">
                  Company <i className="fa fa-angle-down"></i>
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link to="/About-us">About us</Link>
                  </li>
                  <li>
                    <Link to="/History">History</Link>
                  </li>
                  <li>
                    <Link to="/Values">Values</Link>
                  </li>
                                  </ul>
              </li>
              <li>
                <Link to="/Ebrochure">E-Brochure</Link>
              </li>
              <li className="dropdown">
                <Link
                  data-toggle="dropdown"
                  className="dropdown-toggle border-hover-color1"
                  to="#">
                  Services <i className="fa fa-angle-down"></i>
                </Link>
                <ul className="dropdown-menu">
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-166 dropdown">
                    <Link>
                      Freight Forwarding <i className="fa fa-angle-right"></i>
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2114 dropdown">
                        <Link to="/Sea-Freight-Services">
                          Sea Freight Services
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2114 dropdown">
                        <Link to="/Air-Freight-Services">Air Freight</Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2114 dropdown">
                        <Link to="/Sea-Air">Sea Air</Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2114 dropdown">
                        <Link to="/Multimodal-Transportation">
                          Multimodal Transportation
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2114 dropdown">
                        <Link to="/Customs-Brokerage">Customs Brokerage</Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2114 dropdown">
                        <Link to="/Clearence">Clearance</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-166 dropdown">
                    <Link>
                      Supply Chain Solutions{" "}
                      <i className="fa fa-angle-right"></i>
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2114 dropdown">
                        <Link to="/Buyers-MultiCustomer">
                          Buyers & Multi Customer Consolidation
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2114 dropdown">
                        <Link to="/Wearhouse-Distribution">
                          Warehousing & Distribution
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2114 dropdown">
                        <Link to="/Control-Process-Tower">
                          Control Process Tower
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-166 dropdown">
                    <Link>
                      Dedicated Offers <i className="fa fa-angle-right"></i>
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2114 dropdown">
                        <Link to="/Project-Cargo">Project Cargo</Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2114 dropdown">
                        <Link to="/Special-Cargo">Special Cargo</Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2114 dropdown">
                        <Link to="/Qc-Platform">QC Platform</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/News">News</Link>
              </li>
              <li>
                <Link to="/Schedule">Schedule</Link>
              </li>
              <li className="dropdown">
                <Link
                  data-toggle="dropdown"
                  className="dropdown-toggle border-hover-color1"
                  to="affiliated-carriers.html">
                  Affiliated Carriers <i className="fa fa-angle-down"></i>
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link to="/Trusted-Airlines">Airlines</Link>
                  </li>
                  <li>
                    <Link to="/Trusted-Shipping-Lines">Shipping Lines</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/Carrer">Career</Link>
              </li>
              <li>
                <Link to="/Contact">Contact</Link>
              </li>
            </ul>
          </nav>
          <Link id="menu-close" to="#">
            <i className="fa fa-times"></i>
          </Link>
        </div>
      </header>
    </div>
  );
};

export default Header;
