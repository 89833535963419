import react, { useEffect } from "react";
import "./App.css";

import { BrowserRouter as Router } from "react-router-dom";

import RouteLinks from "./RouteLinks";

function App() {
  /* useEffect((e) => {
    e.preventDefault();
  }, []); */
  return (
    <Router>
      <div className="sp-body">
        <RouteLinks />
      </div>
    </Router>
  );
}

export default App;
