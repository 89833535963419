import React, { useEffect } from "react";

const Airlines = () => {
  useEffect(() => {
    document.title = "Trusted Airlines | PartexAeromarine Logistics Ltd";
  }, []);
  return (
    <div>
      <div
        style={{ backgroundColor: "#001122" }}
        className="paralax bg-image page-title">
        <div className="container-fluid">
          <h1 className="title">Trusted Airlines</h1>
          <span className="subtitle"></span>
        </div>
      </div>
      <div id="content" className="content">
        <main className="main-content">
          <div className="container">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="breadcrumbsBox ">
                <a href="home/index.html">
                  <i className="fa fa-home fa-lg"></i>
                </a>
                &nbsp;&nbsp;/&nbsp;&nbsp; Affiliated Carriers
                &nbsp;&nbsp;/&nbsp;&nbsp; Trusted Airlines{" "}
              </div>
            </div>
            <div className="col-xs-12  col-sm-12 col-md-12 ">
              <div className="vc_row wpb_row vc_row-fluid vc_pixrow-no-padding">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner ">
                    <div className="wpb_wrapper">
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper"></div>
                      </div>
                      <div className="row">
                        {" "}
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_05_52_30_airlines.jpg"
                              alt="Fly Emirates"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_05_59_52_airlines2.jpg"
                              alt="British Airways"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_03_47_airlines3.jpg"
                              alt="Cathay Pacific"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_07_48_airlines4.jpg"
                              alt="Etihad Airways"
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        {" "}
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_27_11_21_36_air1.jpg"
                              alt="Turkish Airlines"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_11_21_airlines6.jpg"
                              alt="Biman Bangladesh Airlines"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_12_50_airlines7.jpg"
                              alt="Malaysia Airlines"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_14_23_airlines8.jpg"
                              alt="Thai Airways"
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        {" "}
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_15_53_airlines9.jpg"
                              alt="Saudia"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_16_58_airlines10.jpg"
                              alt="Qatar Airways"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_18_26_airlines11.jpg"
                              alt="Kuwait Airways"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_19_33_airlines12.jpg"
                              alt="Singapore Airlines"
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        {" "}
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_27_11_25_06_air2.jpg"
                              alt="Tiger Airways"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_27_11_26_15_air3.jpg"
                              alt="SilkAir"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_27_11_27_37_air4.jpg"
                              alt="Oman Air"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_27_11_30_36_air5.jpg"
                              alt="AirAsia"
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        {" "}
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_27_11_33_17_air6.jpg"
                              alt="Hong Kong Airlines"
                            />
                          </div>
                        </div>
                      </div>
                      <br />{" "}
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Airlines;
