import React, { useEffect } from "react";

import { Link } from "react-router-dom";

const AirFreight = () => {
  useEffect(() => {
    document.title = "Air Freight | PartexAeromarine Logistics Ltd";
  }, []);
  return (
    <div>
      <div
        style={{
          backgroundImage:
            "url(img_upload/page_2016_09_28_06_40_48_page_2016_07_29_12_30_05_air_comp.jpg)",
          backgroundColor: "#001122",
        }}
        className="paralax bg-image page-title">
        <div className="container-fluid">
          <h1 className="title">Air Freight</h1>
          <span className="subtitle"></span>
        </div>
      </div>
      <div id="content" className="content">
        <main className="main-content">
          <div className="container">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="breadcrumbsBox ">
                <Link to="/">
                  <i className="fa fa-home fa-lg"></i>
                </Link>
                &nbsp;&nbsp;/&nbsp;&nbsp; Services &nbsp;&nbsp;/&nbsp;&nbsp;
                Freight Forwarding &nbsp;&nbsp;/&nbsp;&nbsp; Air Freight{" "}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-3">
              <aside className="sidebar">
                <div
                  className="widget widget_services_category block_content"
                  id="pix-services-widget-2">
                  <ul className="styled clearfix">
                    <li className=" ">
                      <Link className="">Freight Forwarding</Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link
                        to="/Sea-Freight-Services"
                        className="a_sidebar_small">
                        Sea Freight Services
                      </Link>
                    </li>
                    <li className="active_sub li_sidebar_small">
                      <Link
                        to="/Air-Freight-Services"
                        className="a_sidebar_small">
                        Air Freight
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Sea-Air" className="a_sidebar_small">
                        Sea Air
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link
                        to="/Multimodal-Transportation"
                        className="a_sidebar_small">
                        Multimodal Transportation
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Customs-Brokerage" className="a_sidebar_small">
                        Customs Brokerage
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Clearence" className="a_sidebar_small">
                        Clearance
                      </Link>
                    </li>
                    <li className=" " style={{ marginTop: "40px" }}>
                      <Link className="">Supply Chain Solutions</Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link
                        to="/Buyers-MultiCustomer"
                        className="a_sidebar_small">
                        Buyers & Multi Customer Consolidation
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link
                        to="/Wearhouse-Distribution"
                        className="a_sidebar_small">
                        Warehousing & Distribution
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link
                        to="/Control-Process-Tower"
                        className="a_sidebar_small">
                        Control Process Tower
                      </Link>
                    </li>
                    <li className=" " style={{ marginTop: "40px" }}>
                      <Link className="">Dedicated Offers</Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Project-Cargo" className="a_sidebar_small">
                        Project Cargo
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Special-Cargo" className="a_sidebar_small">
                        Special Cargo
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Qc-Platform" className="a_sidebar_small">
                        QC Platform
                      </Link>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>

            <div className="col-xs-12  col-sm-12 col-md-9 ">
              <div className="vc_row wpb_row vc_row-fluid vc_pixrow-no-padding">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner ">
                    <div className="wpb_wrapper">
                      <div className="wpb_single_image wpb_content_element vc_align_left">
                        <figure className="wpb_wrapper vc_figure">
                          <div className="vc_single_image-wrapper   vc_box_border_grey">
                            <img
                              width="100%"
                              alt=""
                              className="vc_single_image-img attachment-full"
                              src="img_upload/page_2016_09_28_06_40_48_page_2016_07_29_12_34_58_airf.jpg"
                            />
                          </div>
                        </figure>
                      </div>
                      <br />

                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <p style={{ textAlign: "justify" }}>
                            Our airfreight service has many specialized
                            solutions. Whether your cargo needs to rapid
                            shipping, precise temperature regulation or requires
                            special handling etc. we have the right product for
                            you.
                          </p>
                          <ol>
                            <li style={{ textAlign: "justify" }}>
                              Committed: PAM relationships with mot leading
                              airlines allow for utilization of committed
                              capacity in urgent or peak season situations.
                              Whether its retail, perishables or high value
                              cargo, we guarantee that we will effectively
                              handle all your shipments and ensure receipt all
                              destinations.
                            </li>
                            <li style={{ textAlign: "justify" }}>
                              Door to Door Services: When managing all aspects
                              of the supply chain, it is essential to be able to
                              deliver the goods as per the customers need.&nbsp;
                              We ensure that we monitor every part of logistics
                              chain once the cargo has reached the destination.
                              Our integrated DTD services are backed by a strong
                              platform which ensures visibility to our
                              customers.
                            </li>
                            <li style={{ textAlign: "justify" }}>
                              Special Services:
                              <ul>
                                <li>
                                  Rapid shipping, precise temperature regulation
                                  or requires special handling such as GOH,
                                  Special Palletization.
                                </li>
                                <li>
                                  A premium services to meet high demands of
                                  tight and time-sensitive cargo.
                                </li>
                                <li>
                                  Premium services based on our own stock
                                  airlines.
                                </li>
                                <li>
                                  Completed with the best proposals from
                                  alternative carriers by our parallel strategy.
                                </li>
                              </ul>
                            </li>
                          </ol>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AirFreight;
