import React, { useEffect } from "react";

import { Link } from "react-router-dom";

const AboutUs = () => {
  useEffect(() => {
    document.title = "About | PartexAeromarine Logistics Ltd";
  }, []);
  return (
    <div>
      <div
        style={{
          backgroundImage: "url(img_upload/page_2016_07_29_12_15_43_car.jpg)",
          backgroundColor: " #001122",
        }}
        className="paralax bg-image page-title">
        <div className="container-fluid">
          <h1 className="title">About us</h1>
          <span className="subtitle"></span>
        </div>
      </div>
      <div id="content" className="content">
        <main className="main-content">
          <div className="container">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="breadcrumbsBox">
                <Link to="/">
                  <i className="fa fa-home fa-lg"></i>
                </Link>
                &nbsp;&nbsp;/&nbsp;&nbsp; Company &nbsp;&nbsp;/&nbsp;&nbsp;
                About us
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-3">
              <aside className="sidebar">
                <div
                  className="widget widget_services_category block_content"
                  id="pix-services-widget-2">
                  <ul className="styled clearfix">
                    <li className="active">
                      <Link to="/About-us" className="">
                        About us
                      </Link>
                    </li>
                    <li className=" ">
                      <Link to="/History" className="">
                        History
                      </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Values" className="">
                        Values
                      </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Management" className="">
                        Management
                      </Link>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-9">
              <div className="vc_row wpb_row vc_row-fluid vc_pixrow-no-padding">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <div className="wpb_single_image wpb_content_element vc_align_left">
                        <figure className="wpb_wrapper vc_figure">
                          <div className="vc_single_image-wrapper vc_box_border_grey">
                            <img
                              width="100%"
                              alt=""
                              className="vc_single_image-img attachment-full"
                              src="img_upload/page_2016_07_29_12_07_19_partexstar_corporate_banner.jpg"
                            />
                          </div>
                        </figure>
                      </div>
                      <br />

                      <div className="wpb_text_column wpb_content_element">
                        <div className="wpb_wrapper">
                          <h3>Partex Star Group</h3>

                          <p align="justify">
                            Partex Star Group is one of the largest Bangladeshi
                            diversified private sector enterprises. The Group,
                            the then Partex Group, started its journey in 1962,
                            with the torchbearer,
                            <strong>Mr. M.A. Hashem</strong>, the founder
                            Chairman of the then Partex Group, an eminent
                            industrialist of the country.
                          </p>

                          <p align="justify">
                            Partex Star Group owns and successfully operates
                            twenty manufacturing, service and trading concerns
                            delivering best values to customers through its
                            products and services. Our business primarily exists
                            in two different areas &ndash; consumer durables
                            like different types of boards(particle board,
                            veneer board, melamine faced chip board, plywood,
                            PVC sheet) doors and door frames &amp; furniture to
                            FMCG like dairy products, cookies, spices, etc.
                          </p>

                          <p align="justify">
                            Our team is dedicated to creating an enriched
                            customer experience through a top-to-bottom focus on
                            excellence in execution. From the hiring of people
                            to the selection of merchandise, the negotiations
                            with vendors to the design of ours showrooms, the
                            generation of the purchase order to the delivery to
                            our customers&rsquo; homes.
                          </p>

                          <p align="justify">
                            A motivated work force, management and committed
                            board members led by the Partex Star Group
                            Chairperson and backed by a market oriented
                            corporate strategy has been the cornerstone of the
                            group&rsquo;s success.
                          </p>

                          <h3>&nbsp;</h3>

                          <h3>About Partex Aeromarine Logistics Ltd</h3>

                          <p>
                            This spectacular unit under the corporate umbrella
                            of Partex Star Group is in a complex and
                            multi-dimensional business that requires a pool of
                            experienced veterans in export-import trading
                            documentations, logistics, freight movement,
                            internal carriage, storage and/or temperate
                            warehousing and related infrastructures.
                            PartexAeromarine Logistics Limited is a trusted
                            logistic handling service provider with excellent
                            reputation.&nbsp; Manned by experienced veterans in
                            export-import trading documentations, logistics,
                            freight movement, internal carriage, storage and/or
                            temperate warehousing and related infrastructures,
                            it&rsquo;s a reputed asset-based third party
                            logistics (3 PL) service provider serving over the
                            years.
                          </p>

                          <p>
                            It is has experienced manpower to conduct the
                            service of customs brokerage for processing both
                            export and import documentations, providing all
                            sorts of transportations including trucking,
                            trailing, GOH and loose cargo loading with its own
                            pool of heavy duty vehicles, warehousing at major
                            customs points, especially undertaking specialized,
                            project-based and turnkey cargo movement. &nbsp;
                          </p>

                          <p>
                            <strong>Global presence</strong>: PAM worldwide
                            footprint spreads across 4 continents and across 3
                            countries own offices. Beside we operate with a
                            strong business partner network across the globe
                            helping us serve you no matter where you are.
                          </p>

                          <p>
                            <strong>Sustainability</strong>: Our business is
                            built on relationships with our customers, partners,
                            investors, employees and the communities in where we
                            live and work. Contributing to the social welfare of
                            our communities is a vital part of our work ethics.
                          </p>

                          <p>
                            <strong>
                              PAM Provide Fastest &amp; Affordable Cargo
                              Services
                            </strong>
                            : Supply Chain Management (SCM) is a key element of
                            every company&rsquo;s strategy and has never been so
                            complex. In order to reduce costs and optimize
                            just-in-times flows, companies need partners who
                            understand their specific needs and develop a
                            customized logistics solution. PAM is a company
                            where have access to a highly skilled network that
                            specializes in over the air, sea, rail and road
                            freight transport.
                          </p>

                          <p>
                            <strong>Social Responsibility</strong>: Social
                            responsibility takes top billing in our operations
                            to consciously contribute in reducing global warming
                            and taking a lead in living and doing business by
                            example and concern for environment &amp; society at
                            large.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AboutUs;
