import React, { useEffect } from "react";

import { Link } from "react-router-dom";

const History = () => {
  useEffect(() => {
    document.title = "History | PartexAeromarine Logistics Ltd";
  }, []);
  return (
    <div>
      <div
        style={{ backgroundColor: "#001122" }}
        className="paralax bg-image page-title">
        <div className="container-fluid">
          <h1 className="title">History</h1>
          <span className="subtitle"></span>
        </div>
      </div>
      <div id="content" className="content">
        <main className="main-content">
          <div className="container">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="breadcrumbsBox ">
                <Link to="/">
                  <i className="fa fa-home fa-lg"></i>
                </Link>
                &nbsp;&nbsp;/&nbsp;&nbsp; Company &nbsp;&nbsp;/&nbsp;&nbsp;
                History{" "}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-3">
              <aside className="sidebar">
                <div
                  className="widget widget_services_category block_content"
                  id="pix-services-widget-2">
                  <ul className="styled clearfix">
                    <li className="">
                      <Link to="/About-us" className="">
                        About us
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/History" className="">
                        History
                      </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Values" className="">
                        Values
                      </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Management" className="">
                        Management
                      </Link>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>

            <div className="col-xs-12  col-sm-12 col-md-9 ">
              <div className="vc_row wpb_row vc_row-fluid vc_pixrow-no-padding">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner ">
                    <div className="wpb_wrapper">
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <p style={{ textAlign: "justify" }}>
                            PartexAeromarine Logistics Ltd. established in April
                            2014 under the corporate umbrella of Partex Star
                            Group in a complex and multi-dimensional business
                            that requires a pool of experienced veterans in
                            export-import trading documentations, logistics,
                            freight movement, internal carriage, storage and/or
                            temperate warehousing and related infrastructures.
                            It was incorporate with Chittagong Port as a freight
                            forwarder same month. PartexAeromarine Logistics
                            Ltd. (PAM) is affiliated with Bangladesh Freight
                            Forwarders Association (BAFFA), World Cargo
                            Association (WCA), Worldwide Project Consortium
                            (WWPC), and Global Affinity Alliance (GAA). PAM has
                            three (03) overseas office in Bangladesh, India and
                            Singapore.
                          </p>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default History;
