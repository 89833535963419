import React from "react";
import { Route, Switch } from "react-router-dom";
import Header from "./pages/Header";
import Main from "./pages/Main";
import Footer from "./pages/Footer";
import AboutUs from "./pages/company/AboutUs";
import History from "./pages/company/History";
import Management from "./pages/company/Management";
import Values from "./pages/company/Values";
import Ebrochure from "./pages/Ebrochure";
import News from "./pages/News";
import Schedule from "./pages/Schedule";
import Carrer from "./pages/Carrer";
import Contact from "./pages/Contact";
import Airlines from "./pages/Affiliation/Airlines";
import ShippingLines from "./pages/Affiliation/ShippingLines";
import SeaFreight from "./pages/services/SeaFreight";
import AirFreight from "./pages/services/AirFreight";
import SeaAir from "./pages/services/SeaAir";
import Multimodal from "./pages/services/Multimodal";
import Customs from "./pages/services/Customs";
import Clearence from "./pages/services/Clearence";
import Qc from "./pages/services/Qc";
import ProjectCargo from "./pages/services/ProjectCargo";
import SpecialCargo from "./pages/services/SpecialCargo";
import Wearhouse from "./pages/services/Wearhouse";
import Buyers from "./pages/services/Buyers";
import Control from "./pages/services/Control";

const RouteLinks = () => {
  return (
    <body data-scrolling-animations="true">
      <Header />
      <Switch>
        <Route path="/" exact>
          <Main />
        </Route>
        <Route path="/About-us">
          <AboutUs />
        </Route>
        <Route path="/History">
          <History />
        </Route>
        <Route path="/Values">
          <Values />
        </Route>
        <Route path="/Management">
          <Management />
        </Route>
        <Route path="/Ebrochure">
          <Ebrochure />
        </Route>
        <Route path="/News">
          <News />
        </Route>
        <Route path="/Schedule">
          <Schedule />
        </Route>
        <Route path="/Carrer">
          <Carrer />
        </Route>
        <Route path="/Contact">
          <Contact />
        </Route>
        <Route path="/Trusted-Airlines">
          <Airlines />
        </Route>
        <Route path="/Trusted-Shipping-Lines">
          <ShippingLines />
        </Route>
        <Route path="/Sea-Freight-Services">
          <SeaFreight />
        </Route>
        <Route path="/Air-Freight-Services">
          <AirFreight />
        </Route>
        <Route path="/Sea-Air">
          <SeaAir />
        </Route>
        <Route path="/Multimodal-Transportation">
          <Multimodal />
        </Route>
        <Route path="/Customs-Brokerage">
          <Customs />
        </Route>
        <Route path="/Clearence">
          <Clearence />
        </Route>
        <Route path="/Qc-Platform">
          <Qc />
        </Route>
        <Route path="/Project-Cargo">
          <ProjectCargo />
        </Route>
        <Route path="/Special-Cargo">
          <SpecialCargo />
        </Route>
        <Route path="/Wearhouse-Distribution">
          <Wearhouse />
        </Route>
        <Route path="/Control-Process-Tower">
          <Control />
        </Route>
        <Route path="/Buyers-MultiCustomer">
          <Buyers />
        </Route>
      </Switch>
      <Footer />
    </body>
  );
};

export default RouteLinks;
