import React, { useEffect } from "react";

const News = () => {
  useEffect(() => {
    document.title = "News | PartexAeromarine Logistics Ltd";
  }, []);
  return (
    <div>
      <div
        style={{ backgroundColor: "#001122" }}
        className="paralax bg-image page-title">
        <div className="container-fluid">
          <h1 className="title">News</h1>
          <span className="subtitle"></span>
        </div>
      </div>

      <div id="content" className="content" style={{ paddingTop: "45px" }}>
        <div className="container">
          <div className="row">
            <div className="posts col-xs-12  col-sm-12 col-md-12 ">
              <div className="big-posts">
                <article
                  data-animation=""
                  className="blog-item post type-post status-publish format-standard has-post-thumbnail  hentry">
                  <div className="entry-main">
                    <h1>
                      <a>PartexAeromarine News 3</a>
                    </h1>
                    <div className="post-info">
                      <span>Posted on September 26, 2016</span>
                    </div>
                    <p>
                      You can use this page to test the handling of the gallery
                      post format. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Integer condimentum hendrerit adipiscing.
                      Duis quam tortor, eleifend et condimentum at, imperdiet
                      vitae nunc. Vivamus rutrum magna vitae libero venenatis,
                      non pretium enim faucibus. Lorem ipsum dolor sit amet,
                      consectetur adipiscing elit.&hellip;
                    </p>{" "}
                  </div>
                </article>

                <i className="decor-brand"></i>
                <article
                  data-animation=""
                  className="blog-item post type-post status-publish format-standard has-post-thumbnail  hentry">
                  <div className="entry-main">
                    <h1>
                      <a>PartexAeromarine News 4</a>
                    </h1>
                    <div className="post-info">
                      <span>Posted on September 26, 2016</span>
                    </div>

                    <p>
                      You can use this page to test the handling of the gallery
                      post format. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Integer condimentum hendrerit adipiscing.
                      Duis quam tortor, eleifend et condimentum at, imperdiet
                      vitae nunc. Vivamus rutrum magna vitae libero venenatis,
                      non pretium enim faucibus. Lorem ipsum dolor sit amet,
                      consectetur adipiscing elit.&hellip;
                    </p>
                  </div>
                </article>
                <i className="decor-brand"></i>

                <article
                  data-animation=""
                  className="blog-item post type-post status-publish format-standard has-post-thumbnail  hentry">
                  <div className="entry-main">
                    <h1>
                      <a>PartexAeromarine News 2</a>
                    </h1>
                    <div className="post-info">
                      <span>Posted on July 21, 2016</span>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                      magna aliquam erat volutpat. Ut wisi enim ad minim veniam.
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour. (more&hellip;)
                    </p>
                  </div>
                </article>
                <i className="decor-brand"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
