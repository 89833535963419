import React, { useEffect } from "react";

const ShippingLines = () => {
  useEffect(() => {
    document.title = "Trusted Shipping Lines | PartexAeromarine Logistics Ltd";
  }, []);
  return (
    <div>
      <div
        style={{ backgroundColor: "#001122" }}
        className="paralax bg-image page-title">
        <div className="container-fluid">
          <h1 className="title">Trusted Shipping Lines</h1>
          <span className="subtitle"></span>
        </div>
      </div>
      <div id="content" className="content">
        <main className="main-content">
          <div className="container">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="breadcrumbsBox ">
                <a href="home/index.html">
                  <i className="fa fa-home fa-lg"></i>
                </a>
                &nbsp;&nbsp;/&nbsp;&nbsp; Affiliated Carriers
                &nbsp;&nbsp;/&nbsp;&nbsp; Trusted Shipping Lines{" "}
              </div>
            </div>
            <div className="col-xs-12  col-sm-12 col-md-12 ">
              <div className="vc_row wpb_row vc_row-fluid vc_pixrow-no-padding">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner ">
                    <div className="wpb_wrapper">
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper"></div>
                      </div>
                      <div className="row">
                        {" "}
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_53_40_ship1.jpg"
                              alt="ANL"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_53_49_ship2.jpg"
                              alt="CMA CGM"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_54_35_ship3.jpg"
                              alt="Hapag-Lloyd"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_54_53_ship4.jpg"
                              alt="Safmarine"
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        {" "}
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_56_12_ship5.jpg"
                              alt="OOCL"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_56_24_ship6.jpg"
                              alt="MCC Transport"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_56_38_ship7.jpg"
                              alt="Yang Ming Marine Transport Corporation"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_56_48_ship8.jpg"
                              alt="Pacific International Lines"
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        {" "}
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_57_09_ship9.jpg"
                              alt="Maersk Line"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_57_17_ship10.jpg"
                              alt="APL"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_57_26_ship11.jpg"
                              alt="MSC"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_25_06_57_34_ship12.jpg"
                              alt="Mitsui O.S.K. Lines"
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        {" "}
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_27_11_40_42_aship1.jpg"
                              alt="Nippon Yusen"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_27_11_41_56_aship2.jpg"
                              alt="Hamburg S�d"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_27_11_44_27_aship3.jpg"
                              alt="EVERGREEN LINE"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_27_11_45_44_aship4.jpg"
                              alt="K Line"
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        {" "}
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_27_11_47_56_aship5.jpg"
                              alt="Hyundai Merchant Marine"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_27_11_49_29_aship6.jpg"
                              alt="Regional Container Lines"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_27_11_51_07_aship7.jpg"
                              alt="cosco line"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <img
                              src="img_upload/aff_2016_09_27_11_52_06_aship8.jpg"
                              alt="????"
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row"></div>
                      <br />{" "}
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ShippingLines;
