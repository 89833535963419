import React, { useEffect } from "react";

import { Link } from "react-router-dom";

const Values = () => {
  useEffect(() => {
    document.title = "Values | PartexAeromarine Logistics Ltd";
  }, []);
  return (
    <div>
      <div
        style={{ backgroundColor: "#001122" }}
        className="paralax bg-image page-title">
        <div className="container-fluid">
          <h1 className="title">Our Values</h1>
          <span className="subtitle"></span>
        </div>
      </div>
      <div id="content" className="content">
        <main className="main-content">
          <div className="container">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="breadcrumbsBox ">
                <Link to="/">
                  <i className="fa fa-home fa-lg"></i>
                </Link>
                &nbsp;&nbsp;/&nbsp;&nbsp; Company &nbsp;&nbsp;/&nbsp;&nbsp; Our
                Values{" "}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-3">
              <aside className="sidebar">
                <div
                  className="widget widget_services_category block_content"
                  id="pix-services-widget-2">
                  <ul className="styled clearfix">
                    <li className="">
                      <Link to="/About-us" className="">
                        About us
                      </Link>
                    </li>
                    <li className=" ">
                      <Link to="/History" className="">
                        History
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/Values" className="">
                        Values
                      </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Management" className="">
                        Management
                      </Link>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>

            <div className="col-xs-12  col-sm-12 col-md-9 ">
              <div className="vc_row wpb_row vc_row-fluid vc_pixrow-no-padding">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner ">
                    <div className="wpb_wrapper">
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <h1>Our Values and Strength</h1>
                        </div>
                      </div>

                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <p style={{ textAlign: "justify" }}>
                            <strong>Commitment to our clients: </strong>
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            We committed to provide you quality services as a
                            key to our strong business relationship. We
                            understand your needs are unique. Perhaps your goods
                            must meet product specific regulations, or you need
                            a customized program. We will tailor our services to
                            your situation.
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            <strong>Market Knowledge: </strong>
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Fully understanding the operations within your
                            territory is very important to execute best
                            logistics practices. Our members have thorough
                            knowledge that can ensure swift and smooth
                            operations, move by move.
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            <strong>
                              More customer focus and commitment:{" "}
                            </strong>
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            Growing with our customers is very important to our
                            business. Due to the nature of our outfit, being a
                            medium sized player, we treat all our customers with
                            highest respect and provide always 100% commitment
                            and concentration.
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            <strong>Teamwork: </strong>
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            We are a winning team of talented people, managed
                            through deliberate consensus, deriving expertise
                            from all levels of the company, our people are
                            motivated and rewarded as they contribute to
                            increasing long-term value of our company.
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            <strong>Innovation: </strong>
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            As members of PAM, we are committed to constantly
                            challenging ourselves to deliver increasing value to
                            our customers by making their supply chain as
                            efficient as possible.
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            <strong>Continuous Improvement: </strong>
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            We are committed to the constant effort of
                            developing and improving our services and
                            technology. This commitment is found in the beach
                            that by continuously adding value in innovative
                            ways, we will assure long-term success.
                          </p>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Values;
