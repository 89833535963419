import React, { useEffect } from "react";

import { Link } from "react-router-dom";

const Management = () => {
  useEffect(() => {
    document.title = "Management | PartexAeromarine Logistics Ltd";
  }, []);
  return (
    <div>
      <div
        style={{ backgroundColor: "#001122" }}
        class="paralax bg-image page-title">
        <div class="container-fluid">
          <h1 class="title">Management</h1>
          <span class="subtitle"></span>
        </div>
      </div>
      <div id="content" class="content">
        <main class="main-content">
          <div class="container">
            <div class="col-xs-12 col-sm-12 col-md-12">
              <div class="breadcrumbsBox ">
                <Link to="/">
                  <i class="fa fa-home fa-lg"></i>
                </Link>
                &nbsp;&nbsp;/&nbsp;&nbsp; Company &nbsp;&nbsp;/&nbsp;&nbsp;
                Management{" "}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-3">
              <aside className="sidebar">
                <div
                  className="widget widget_services_category block_content"
                  id="pix-services-widget-2">
                  <ul className="styled clearfix">
                    <li className="">
                      <Link to="/About-us" className="">
                        About us
                      </Link>
                    </li>
                    <li className=" ">
                      <Link to="/History" className="">
                        History
                      </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Values" className="">
                        Values
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/Management" className="">
                        Management
                      </Link>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>

            <div class="col-xs-12  col-sm-12 col-md-9 ">
              <div class="vc_row wpb_row vc_row-fluid ">
                <div class="wpb_column vc_column_container vc_col-sm-12">
                  <div class="vc_column-inner ">
                    <div class="wpb_wrapper">
                      <div class="wpb_text_column wpb_content_element ">
                        <div class="wpb_wrapper">
                          <h1>Board of Directors</h1>
                        </div>
                      </div>
                      <div class="wpb_text_column wpb_content_element ">
                        <div class="wpb_wrapper"></div>
                      </div>
                      <div>
                        <div class="row team style-2">
                          <div class="col-md-4">
                            <div
                              style={{
                                backgroundImage:
                                  "url(img_upload/personal_2016_07_29_01_38_34_sultana_hashem.jpg)",
                              }}
                              class="userpic"></div>
                            <div class="user-info text-center">
                              <small>Chairman</small>
                              <h4>Sultana Hashem</h4>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div
                              style={{
                                backgroundImage:
                                  "url(img_upload/personal_2016_07_29_01_39_06_bod_aziz_al_kaiser.jpg)",
                              }}
                              class="userpic"></div>
                            <div class="user-info text-center">
                              <small>Vice Chairman</small>
                              <h4>Mr. Aziz Al Kaiser</h4>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div
                              style={{
                                backgroundImage:
                                  "url(img_upload/personal_2016_07_29_01_39_28_bod_aziz_al_mahmood.jpg)",
                              }}
                              class="userpic"></div>
                            <div class="user-info text-center">
                              <small>Managing Director</small>
                              <h4>Mr. Aziz Al Mahmood</h4>
                            </div>
                          </div>

                          <div class="col-md-2"></div>
                          <div class="col-md-4">
                            <div
                              style={{
                                backgroundImage:
                                  "url(img_upload/personal_2016_07_29_01_39_49_bod_tabassum_kaiser.jpg)",
                              }}
                              class="userpic"></div>
                            <div class="user-info text-center">
                              <small>Director</small>
                              <h4>Tabassum Kaiser</h4>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div
                              style={{
                                backgroundImage:
                                  "url(img_upload/personal_2016_07_29_01_40_01_bod_savera_h._ahmood.jpg)",
                              }}
                              class="userpic"></div>
                            <div class="user-info text-center">
                              <small>Director</small>
                              <h4>Savera H. Mahmood</h4>
                            </div>
                          </div>
                          <div class="col-md-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Management;
