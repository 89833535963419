import React, { useEffect } from "react";

import { Link } from "react-router-dom";

const Wearhouse = () => {
  useEffect(() => {
    document.title = "Wearhousing | PartexAeromarine Logistics Ltd";
  }, []);
  return (
    <div>
      <div
        style={{ backgroundColor: "#001122" }}
        className="paralax bg-image page-title">
        <div className="container-fluid">
          <h1 className="title">Warehousing & Distribution</h1>
          <span className="subtitle"></span>
        </div>
      </div>
      <div id="content" className="content">
        <main className="main-content">
          <div className="container">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="breadcrumbsBox ">
                <Link to="/">
                  <i className="fa fa-home fa-lg"></i>
                </Link>
                &nbsp;&nbsp;/&nbsp;&nbsp; Services &nbsp;&nbsp;/&nbsp;&nbsp;
                Supply Chain Solutions &nbsp;&nbsp;/&nbsp;&nbsp; Warehousing &
                Distribution{" "}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-3">
              <aside className="sidebar">
                <div
                  className="widget widget_services_category block_content"
                  id="pix-services-widget-2">
                  <ul className="styled clearfix">
                    <li className=" ">
                      <Link className="">Freight Forwarding</Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link
                        to="/Sea-Freight-Services"
                        className="a_sidebar_small">
                        Sea Freight Services
                      </Link>
                    </li>
                    <li className="li_sidebar_small">
                      <Link
                        to="/Air-Freight-Services"
                        className="a_sidebar_small">
                        Air Freight
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Sea-Air" className="a_sidebar_small">
                        Sea Air
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link
                        to="/Multimodal-Transportation"
                        className="a_sidebar_small">
                        Multimodal Transportation
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Customs-Brokerage" className="a_sidebar_small">
                        Customs Brokerage
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Clearence" className="a_sidebar_small">
                        Clearance
                      </Link>
                    </li>
                    <li className=" " style={{ marginTop: "40px" }}>
                      <Link className="">Supply Chain Solutions</Link>
                    </li>
                    <li className="li_sidebar_small">
                      <Link
                        to="/Buyers-MultiCustomer"
                        className="a_sidebar_small">
                        Buyers & Multi Customer Consolidation
                      </Link>
                    </li>
                    <li className="active_sub li_sidebar_small">
                      <Link
                        to="/Wearhouse-Distribution"
                        className="a_sidebar_small">
                        Warehousing & Distribution
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link
                        to="/Control-Process-Tower"
                        className="a_sidebar_small">
                        Control Process Tower
                      </Link>
                    </li>
                    <li className=" " style={{ marginTop: "40px" }}>
                      <Link className="">Dedicated Offers</Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Project-Cargo" className="a_sidebar_small">
                        Project Cargo
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Special-Cargo" className="a_sidebar_small">
                        Special Cargo
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Qc-Platform" className="a_sidebar_small">
                        QC Platform
                      </Link>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>

            <div className="col-xs-12  col-sm-12 col-md-9 ">
              <div className="vc_row wpb_row vc_row-fluid vc_pixrow-no-padding">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner ">
                    <div className="wpb_wrapper">
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <p style={{ textAlign: "justify" }}>
                            With the growing need of cost effectiveness and
                            reliable solutions for logistics and warehousing,
                            PAM manages and maintains the warehouse solutions.
                            From solutions for retail to specialized cargo
                            storage, we have established best practices to
                            manage your inventories and ensure timely
                            deliveries. &nbsp;
                          </p>
                          <ul style={{ listStyleType: "circle" }}>
                            <li style={{ textAlign: "justify" }}>
                              Integrated solutions for warehousing and
                              distribution center with trucking facility
                            </li>
                            <li style={{ textAlign: "justify" }}>
                              Cost effectiveness and reliable solutions by
                              Logistics Management Systems
                            </li>
                            <li style={{ textAlign: "justify" }}>
                              Own warehouse facility nearby DAC Airport cargo
                              village
                            </li>
                            <li style={{ textAlign: "justify" }}>
                              Worldwide warehousing capabilities with pick &amp;
                              pack on-time distribution
                            </li>
                            <li>Storage/Multi transported Distribution</li>
                            <li>Personal/Household effect storage</li>
                            <li>Innovative product packing and re-packing</li>
                            <li>
                              Pick up &amp; delivery facility by self-pick up at
                              origin and also destinations
                            </li>
                          </ul>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Wearhouse;
