import React, { useEffect } from "react";

import { Link } from "react-router-dom";

const ProjectCargo = () => {
  useEffect(() => {
    document.title = "Project Cargo | PartexAeromarine Logistics Ltd";
  }, []);
  return (
    <div>
      <div
        style={{ backgroundColor: "#001122" }}
        className="paralax bg-image page-title">
        <div className="container-fluid">
          <h1 className="title">Project Cargo</h1>
          <span className="subtitle"></span>
        </div>
      </div>
      <div id="content" className="content">
        <main className="main-content">
          <div className="container">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="breadcrumbsBox ">
                <Link to="/">
                  <i className="fa fa-home fa-lg"></i>
                </Link>
                &nbsp;&nbsp;/&nbsp;&nbsp; Services &nbsp;&nbsp;/&nbsp;&nbsp;
                Dedicated Offers &nbsp;&nbsp;/&nbsp;&nbsp; Project Cargo{" "}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-3">
              <aside className="sidebar">
                <div
                  className="widget widget_services_category block_content"
                  id="pix-services-widget-2">
                  <ul className="styled clearfix">
                    <li className=" ">
                      <Link className="">Freight Forwarding</Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link
                        to="/Sea-Freight-Services"
                        className="a_sidebar_small">
                        Sea Freight Services
                      </Link>
                    </li>
                    <li className="li_sidebar_small">
                      <Link
                        to="/Air-Freight-Services"
                        className="a_sidebar_small">
                        Air Freight
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Sea-Air" className="a_sidebar_small">
                        Sea Air
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link
                        to="/Multimodal-Transportation"
                        className="a_sidebar_small">
                        Multimodal Transportation
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Customs-Brokerage" className="a_sidebar_small">
                        Customs Brokerage
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Clearence" className="a_sidebar_small">
                        Clearance
                      </Link>
                    </li>
                    <li className=" " style={{ marginTop: "40px" }}>
                      <Link className="">Supply Chain Solutions</Link>
                    </li>
                    <li className="li_sidebar_small">
                      <Link
                        to="/Buyers-MultiCustomer"
                        className="a_sidebar_small">
                        Buyers & Multi Customer Consolidation
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link
                        to="/Wearhouse-Distribution"
                        className="a_sidebar_small">
                        Warehousing & Distribution
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link
                        to="/Control-Process-Tower"
                        className="a_sidebar_small">
                        Control Process Tower
                      </Link>
                    </li>
                    <li className=" " style={{ marginTop: "40px" }}>
                      <Link className="">Dedicated Offers</Link>
                    </li>
                    <li className="active_sub  li_sidebar_small">
                      <Link to="/Project-Cargo" className="a_sidebar_small">
                        Project Cargo
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Special-Cargo" className="a_sidebar_small">
                        Special Cargo
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Qc-Platform" className="a_sidebar_small">
                        QC Platform
                      </Link>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>

            <div className="col-xs-12  col-sm-12 col-md-9 ">
              <div className="vc_row wpb_row vc_row-fluid vc_pixrow-no-padding">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner ">
                    <div className="wpb_wrapper">
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <p>Fully project logistics Capabilities Company.</p>
                          <ul>
                            <li>Power Plant Transfer</li>
                            <li>Manufacturing Plant</li>
                            <li>Fully Equipped Industrial plant imports</li>
                            <li>Mega Construction (ODA) Project</li>
                          </ul>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ProjectCargo;
