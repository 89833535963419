import React, { useEffect } from "react";

const Carrer = () => {
  useEffect(() => {
    document.title = "Carrer | PartexAeromarine Logistics Ltd";
  }, []);
  return (
    <div>
      <div
        style={{ backgroundColor: "#001122" }}
        className="paralax bg-image page-title">
        <div className="container-fluid">
          <h1 className="title">Careers with us</h1>
          <span className="subtitle"></span>
        </div>
      </div>
      <div id="content" className="content">
        <main className="main-content">
          <div className="container">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="breadcrumbsBox ">
                <a href="../home/index.html">
                  <i className="fa fa-home fa-lg"></i>
                </a>
                &nbsp;&nbsp;/&nbsp;&nbsp; Careers with us{" "}
              </div>
            </div>
            <div className="col-xs-12  col-sm-12 col-md-12 ">
              <div className="vc_row wpb_row vc_row-fluid vc_pixrow-no-padding">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner ">
                    <div className="wpb_wrapper">
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <h1>Career at Partex Aeromarine Logistics Ltd</h1>
                        </div>
                      </div>

                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <p>
                            We take pride in our passionate, diverse and highly
                            motivated workforce in order to deliver Logistics
                            solution globally, we seek to employ not just the
                            best of the best, we also reinforce continues
                            learning with an ongoing professional relationship
                            with our people in terms of supporting their
                            learning and personal development.&nbsp;
                          </p>
                          <p>
                            We support and facilitate a consistence and dynamic
                            environment that supports the advancement of high
                            performers. If you are keen to develop a career that
                            can truly span the globe, send us your resume at
                            info@pambd.com
                          </p>{" "}
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Carrer;
