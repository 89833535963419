import React, { useEffect } from "react";

const Schedule = () => {
  useEffect(() => {
    document.title = "Schedule | PartexAeromarine Logistics Ltd";
  }, []);
  return (
    <div>
      <div
        style={{ backgroundColor: "#001122" }}
        className="paralax bg-image page-title">
        <div className="container-fluid">
          <h1 className="title">Feeder Schedules</h1>
          <span className="subtitle"></span>
        </div>
      </div>
      <div id="content" className="content">
        <main className="main-content">
          <div className="container">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="breadcrumbsBox ">
                <a href="../home/index.html">
                  <i className="fa fa-home fa-lg"></i>
                </a>
                &nbsp;&nbsp;/&nbsp;&nbsp; Feeder Schedules{" "}
              </div>
            </div>
            <div className="col-xs-12  col-sm-12 col-md-12 ">
              <div className="vc_row wpb_row vc_row-fluid vc_pixrow-no-padding">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner ">
                    <div className="wpb_wrapper">
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper"></div>
                      </div>
                      <table width="100%" className="tabl">
                        <thead>
                          <th width=""> SN.</th>
                          <th width=""> Vessel Name</th>
                          <th width=""> VOY</th>
                          <th width=""> Cut Off</th>
                          <th width=""> ETB CGP</th>
                          <th width=""> ETD CGP</th>
                          <th width=""> T/S PORT</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>QTS34Rx</td>
                            <td>QTS34Rx</td>
                            <td>March 03, 2016</td>
                            <td>March 04, 2016</td>
                            <td>March 05, 2016</td>
                            <td>SIN/PKLx</td>
                          </tr>

                          <tr>
                            <td>2</td>
                            <td>X-PRESS LHOTSE</td>
                            <td>811</td>
                            <td>April 06, 2016</td>
                            <td>April 14, 2016</td>
                            <td>April 30, 2016</td>
                            <td>PEN/SIN</td>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                      <br />{" "}
                      <table width="100%" className="tabl">
                        <thead>
                          <th width=""> SN.</th>
                          <th width=""> Sailed Vessel Name</th>
                          <th width=""> VOY</th>
                          <th width=""> Sailed @</th>
                          <th width=""> ETB CGP</th>
                          <th width=""> ETD CGP</th>
                          <th width=""> T/S PORT</th>
                          <th width=""> MLO</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>X-PRESS LHOTSE</td>
                            <td>1508</td>
                            <td>@12.30PM</td>
                            <td>June 02, 2016</td>
                            <td>June 16, 2016</td>
                            <td>TPP</td>
                            <td>MBDL</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Schedule;
