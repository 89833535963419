import React from "react";

const Main = () => {
  return (
    <div>
      <div
        id="owl-main-slider"
        className="owl-carousel enable-owl-carousel"
        data-single-item="true"
        data-pagination="false"
        data-auto-play="true"
        data-main-slider="true"
        data-stop-on-hover="true">
        <div className="item">
          <img
            src="img_upload/slide_2016_07_30_05_57_27_air_slide.jpg"
            alt="slider"
          />
          <div className="container-fluid">
            <div className="slider-content col-md-6 col-lg-6">
              <div style={{ display: "table" }}>
                <div
                  style={{
                    display: "table-cell",
                    width: "100px",
                    verticalAlign: "left",
                  }}>
                  <a className="prev">
                    <i className="fa fa-angle-left"></i>
                  </a>
                  <a className="next">
                    <i className="fa fa-angle-right"></i>
                  </a>
                </div>
                <div style={{ display: "table-cell" }}>
                  <h1>Large Number of Fleet makes us powerful</h1>
                </div>
              </div>
              <p></p>
            </div>
          </div>
        </div>
        <div className="item">
          <img
            src="img_upload/slide_2016_07_30_04_59_41_img_5_1392295253_1464161282.jpg"
            alt="slider"
          />
          <div className="container-fluid">
            <div className="slider-content col-md-6 col-lg-6">
              <div style={{ display: "table" }}>
                <div
                  style={{
                    display: "table-cell",
                    width: "100px",
                    verticalAlign: "top",
                  }}>
                  <a className="prev">
                    <i className="fa fa-angle-left"></i>
                  </a>
                  <a className="next">
                    <i className="fa fa-angle-right"></i>
                  </a>
                </div>
                <div style={{ display: "table-cell" }}>
                  <h1>Large Number of Fleet makes us powerful</h1>
                </div>
              </div>
              <p>
                We Offer Design for you from Initial Sketches to Final
                Construction
              </p>
            </div>
          </div>
        </div>
        <div className="item">
          <img
            src="img_upload/slide_2016_07_30_04_58_51_logis_slide.jpg"
            alt="slider"
          />
          <div className="container-fluid">
            <div className="slider-content col-md-6 col-lg-6">
              <div style={{ display: "table" }}>
                <div
                  style={{
                    display: "table-cell",
                    width: "100px",
                    verticalAlign: "top",
                  }}>
                  <a className="prev">
                    <i className="fa fa-angle-left"></i>
                  </a>
                  <a className="next">
                    <i className="fa fa-angle-right"></i>
                  </a>
                </div>
                <div style={{ display: "table-cell" }}>
                  <h1>Large Number of Fleet makes us powerful</h1>
                </div>
              </div>
              <p></p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row column-info block-content">
          <div className="col-sm-4 col-md-4 col-lg-4">
            <img
              src="img_upload/personal_2016_07_30_03_47_43_11.jpg"
              alt="slider"
            />
            <span></span>
          </div>
          <div className="col-sm-4 col-md-4 col-lg-8">
            <h2>About PartexAeromarine Logistics Ltd</h2>
            <p style={{ textAlign: "justify" }}>
              PAM ( PartexAeromarine Logistics Ltd.) is a multi-dimensional
              service providers that requires a pool of experienced veterans in
              export&nbsp; &amp; import trading documentations, logistics,
              freight movement, internal carriage, storage&nbsp; and temperate
              warehousing and related infrastructures, PAM is a reputed asset
              based third party logistics (3PL) services provider serving over
              the years of unflinching reputation. PAM has the capability of
              handling both sea and air cargo for exporters as well as importers
              and to those whose business relates to both ends.
            </p>

            <p style={{ textAlign: "justify" }}>
              It is equipped with dedicated experienced team to conduct the
              service of Customs brokerage for processing both export and import
              documentations, providing all sort of transportations including
              trucking, trailing, GOH and loose cargo loading with its own pool
              of heavy duty vehicles, warehousing at major Customs points,
              especially undertaking specialized, project based and turnkey
              cargo movement etc. In the country&#39;s surging industrial sector
              PAM is a trusted logistic handling service provider with excellent
              reputation.
            </p>
          </div>
        </div>
      </div>

      <hr />
      <div className="big-hr color-1">
        <div className="text-left" style={{ textAlign: "center" }}>
          <h2>WE PROVIDE FASTEST & AFFORDABLE CARGO SERVICES</h2>
          <p>
            You have access to a highly skilled network that specializes in over
            the air, sea, sea air, road, and rail freight transport.
          </p>
        </div>
      </div>
      <div className="container-fluid block-content">
        <div className="text-center hgroup">
          <h1>OUR SERVICES</h1>
          <h2>
            We provide Freight Forwarding, C&F, transportation and warehousing
            like- Trailer, Covered Van and Garments on Hanger (GoH) Services at
            your convenience.
          </h2>
        </div>
        <div className="row our-services">
          <div className="col-sm-6 col-md-4 col-lg-4">
            <a href="http://draft.bysontech.com/partex_cargo/sea-freight">
              <span>
                <i
                  className="fa fa-ship"
                  style={{ fontSize: "40px", marginTop: "5px" }}></i>
              </span>
              <h4>Sea Freight</h4>
              <p>
                If low costs matter for your shipment, try our sea freight
                services
              </p>
            </a>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <a href="http://draft.bysontech.com/partex_cargo/air-freight">
              <span>
                <i
                  className="fa fa-plane"
                  style={{ fontSize: "40px", marginTop: "5px" }}></i>
              </span>
              <h4>Air Freight</h4>
              <p>
                When time is crucial for your cargo, our air freight services
                are your best option
              </p>
            </a>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <a href="http://draft.bysontech.com/partex_cargo/customs-brokerage">
              <span>
                <i
                  className="fa fa-cube"
                  style={{ fontSize: "40px", marginTop: "5px" }}></i>
              </span>
              <h4>Customs brokerage</h4>
              <p>
                Integer congue elit non semper laore lectus orci posuer nisl
                tempor lacus mauris led ipsum.
              </p>
            </a>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <a href="http://draft.bysontech.com/partex_cargo/logistics-warehousing">
              <span>
                <i
                  className="fa fa-truck"
                  style={{ fontSize: "40px", marginTop: "5px" }}></i>
              </span>
              <h4>Project & Special Cargo</h4>
              <p>
                Integer congue elit non semper laore lectus orci posuer nisl
                tempor lacus mauris led ipsum.
              </p>
            </a>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <a href="#">
              <span>
                <i
                  className="fa fa-building-o"
                  style={{ fontSize: "40px", marginTop: "5px" }}></i>
              </span>
              <h4>Warehousing & Distribution</h4>
              <p></p>
            </a>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <a href="#">
              <span>
                <i
                  className="fa fa-road fa fa-ship"
                  style={{ fontSize: "40px", marginTop: "5px" }}></i>
              </span>
              <h4>Multimodal Transportation</h4>
              <p></p>
            </a>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <a href="#">
              <span>
                <i
                  className="fa fa-bullhorn"
                  style={{ fontSize: "40px", marginTop: "5px" }}></i>
              </span>
              <h4>Control Process Tower</h4>
              <p></p>
            </a>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <a href="#">
              <span>
                <i
                  className="fa fa-road"
                  style={{ fontSize: "40px", marginTop: "5px" }}></i>
              </span>
              <h4>QC Platform</h4>
              <p></p>
            </a>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <a href="#">
              <span>
                <i
                  className="fa fa-users"
                  style={{ fontSize: "40px", marginTop: "5px" }}></i>
              </span>
              <h4>Buyers & Multi Customer Consolidation</h4>
              <p></p>
            </a>
          </div>
        </div>
      </div>

      <div className="fleet-gallery block-content bg-image inner-offset">
        <div className="container-fluid inner-offset">
          <div data-wow-delay="0.3s" className="text-center hgroup wow">
            <h1>The Fleet Gallery</h1>
            {/*<h2>we always use best &amp; fastest fleets</h2>*/}
          </div>
          <div className="col-md-3">
            {/*<a href="img_upload/image_2016_10_01_01_42_14_1.png" rel="prettyPhoto" >*/}
            <img alt="Img" src="img_upload/image_2016_10_01_01_42_14_1.png" />
            {/*</a>*/}
          </div>
          <div className="col-md-3">
            {/*<a href="img_upload/image_2016_10_01_01_42_14_2.png" rel="prettyPhoto" >*/}
            <img alt="Img" src="img_upload/image_2016_10_01_01_42_14_2.png" />
            {/*</a>*/}
          </div>
          <div className="col-md-3">
            {/*<a href="img_upload/image_2016_10_01_01_42_14_3.png" rel="prettyPhoto" >*/}
            <img alt="Img" src="img_upload/image_2016_10_01_01_42_14_3.png" />
            {/*</a>*/}
          </div>
          <div className="col-md-3">
            {/*<a href="img_upload/image_2016_10_01_01_42_19_4.png" rel="prettyPhoto" >*/}
            <img alt="Img" src="img_upload/image_2016_10_01_01_42_19_4.png" />
            {/*</a>*/}
          </div>
        </div>
      </div>
      <div style={{ height: "1px" }}></div>
      <div
        data-waypoint-scroll="true"
        className="container-fluid block-content percent-blocks">
        <div className="row stats">
          <div className="col-sm-6 col-md-3 col-lg-3">
            <div data-percent="65" className="chart">
              <span>
                <i className="fa fa-folder-open falarge"></i>
              </span>
              <span className="percent">65</span>Project Done
              <canvas height="0" width="0"></canvas>
            </div>
          </div>

          <div className="col-sm-6 col-md-3 col-lg-3">
            <div data-percent="48" className="chart">
              <span>
                <i className="fa fa-users falarge"></i>
              </span>
              <span className="percent">48</span>Clients Worldwide
              <canvas height="0" width="0"></canvas>
            </div>
          </div>

          <div className="col-sm-6 col-md-3 col-lg-3">
            <div data-percent="03" className="chart">
              <span>
                <i className="fa fa-truck falarge"></i>
              </span>
              <span className="percent">03</span>Oversea Own Office
              <canvas height="0" width="0"></canvas>
            </div>
          </div>

          <div className="col-sm-6 col-md-3 col-lg-3">
            <div data-percent="30" className="chart">
              <span>
                <i className="fa fa-male falarge"></i>
              </span>
              <span className="percent">30</span>People in Team
              <canvas height="0" width="0"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div className="block-content bg-image blog-section inner-offset">
        <div className="container-fluid">
          <div align="center">
            <h1>LATEST NEWS</h1>
          </div>

          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6 two-news">
              <div className="news-item row">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="mainimageBG"></div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div>
                    <a href="#">
                      <h3>PartexAeromarine News 3</h3>
                    </a>
                    <small>September 26, 2016</small>
                  </div>
                </div>
              </div>

              <div className="news-item row">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="mainimageBG2"></div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div>
                    <a href="#">
                      <h3>PartexAeromarine News 1</h3>
                    </a>
                    <small>July 23, 2016</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 two-news">
              <div className="news-item row">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="mainimageBG3"></div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div>
                    <a href="#">
                      <h3>PartexAeromarine News 4</h3>
                    </a>
                    <small>September 26, 2016</small>
                  </div>
                </div>
              </div>

              <div className="news-item row">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="mainimageBG4"></div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div>
                    <a href="#">
                      <h3>PartexAeromarine News 2</h3>
                    </a>
                    <small>July 21, 2016</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="hgroup" align="center">
              <h1>WHY CHOOSE US</h1>
              {/*<h2>Lorem ipsum dolor sit amet consectetur</h2>*/}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <ul className="why-us">
              <li style={{ display: "flex" }}>
                <div style={{ float: "left", width: "30px" }}>
                  <i className="fa fa-check-square-o" aria-hidden="true"></i>
                </div>
                <div>PAM is a sister concern of PartexStar Group.</div>
              </li>
              <li style={{ display: "flex" }}>
                <div style={{ float: "left", width: "30px" }}>
                  <i className="fa fa-check-square-o" aria-hidden="true"></i>
                </div>
                <div>
                  Capable to handle all sort of sea & air cargo both import &
                  export documents.
                </div>
              </li>
              <li style={{ display: "flex" }}>
                <div style={{ float: "left", width: "30px" }}>
                  <i className="fa fa-check-square-o" aria-hidden="true"></i>
                </div>
                <div>
                  Provide C& F services for both Import & Export documents.
                </div>
              </li>
              <li style={{ display: "flex" }}>
                <div style={{ float: "left", width: "30px" }}>
                  <i className="fa fa-check-square-o" aria-hidden="true"></i>
                </div>
                <div>Has skilled and knowledgeable workforce.</div>
              </li>
              <li style={{ display: "flex" }}>
                <div style={{ float: "left", width: "30px" }}>
                  <i className="fa fa-check-square-o" aria-hidden="true"></i>
                </div>
                <div>Provides best service and least cost.</div>
              </li>
            </ul>
          </div>

          <div className="col-md-6 col-lg-6">
            <ul className="why-us">
              <li style={{ display: "flex" }}>
                <div style={{ float: "left", width: "30px" }}>
                  <i className="fa fa-check-square-o" aria-hidden="true"></i>
                </div>
                <div>
                  PAM is an asset based third party logistics (3PL) Services
                  provider.
                </div>
              </li>
              <li style={{ display: "flex" }}>
                <div style={{ float: "left", width: "30px" }}>
                  <i className="fa fa-check-square-o" aria-hidden="true"></i>
                </div>
                <div>
                  Provides all types of Transportation including Trucking/
                  Trailering/ Garments on Hanger (GOH) and LCL.
                </div>
              </li>
              <li style={{ display: "flex" }}>
                <div style={{ float: "left", width: "30px" }}>
                  <i className="fa fa-check-square-o" aria-hidden="true"></i>
                </div>
                <div>Ensures one stop service for your cargo.</div>
              </li>
              <li style={{ display: "flex" }}>
                <div style={{ float: "left", width: "30px" }}>
                  <i className="fa fa-check-square-o" aria-hidden="true"></i>
                </div>
                <div>Has own space in different private depots.</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr />
      <div className="container-fluid partners block-content">
        <div className="hgroup title-space wow" align="center">
          <h1>Affiliated to</h1>
        </div>

        <div className="row">
          <div className="col-md-15">
            <div align="center">
              <img
                src="img_upload/associate_2016_09_24_03_45_08_5.jpg"
                className="img-responsive"
              />
            </div>
          </div>
          <div className="col-md-15">
            <div align="center">
              <a href="http://www.google.com/" target="_blank">
                <img
                  src="img_upload/associate_2016_09_24_03_45_14_1.jpg"
                  className="img-responsive"
                />
              </a>
            </div>
          </div>
          <div className="col-md-15">
            <div align="center">
              <img
                src="img_upload/associate_2016_09_24_03_45_18_3.jpg"
                className="img-responsive"
              />
            </div>
          </div>
          <div className="col-md-15">
            <div align="center">
              <img
                src="img_upload/associate_2016_09_24_03_45_22_4.jpg"
                className="img-responsive"
              />
            </div>
          </div>
          <div className="col-md-15">
            <div align="center">
              <img
                src="img_upload/associate_2016_09_24_03_45_27_2.jpg"
                className="img-responsive"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
