import React from "react";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer>
        <div className="color-part2"></div>
        {/*<div className="color-part"></div>*/}
        <div className="container-fluid">
          <div className="row block-content">
            <div className="col-sm-4 wow">
              <h4>CONTACT INFO</h4>
              <div className="contact-info">
                <span>
                  <i className="fa fa-location-arrow"></i>
                  <strong>PartexAeromarine Logistics Ltd</strong> <br />
                  <div style={{ paddingLeft: "18px" }}>
                    Shanta Western Tower, Level-13,&nbsp; Bir Uttam Mir Shawkat
                    Road 186,Tejgaon I/A (Gulshan Link Road)&nbsp; Dhaka &ndash;
                    1208, Bangladesh
                  </div>
                </span>
                <span>
                  <i className="fa fa-phone"></i>+88 02 887 8815
                </span>
                <span>
                  <i className="fa fa-envelope"></i>info@pambd.com,
                  shah.alam@pambd.com
                </span>
                <span>
                  <i className="fa fa-clock-o"></i>Sun - Thu 09:00 AM - 05:00 PM
                  BST (GMT + 6)
                </span>
              </div>
            </div>
            <div className="col-sm-3 wow">
              <h4>OUR SERVICES</h4>
              <nav>
                <Link to="/Sea-Freight-Services">Sea Freight</Link>
                <Link to="/Air-Freight-Services">Air Freight</Link>
                <Link to="/Customs-Brokerage">Customs brokerage</Link>
                <Link to="/Project-Cargo">Project Cargo</Link>
                <Link to="/Wearhouse-Distribution">
                  Warehousing & Distribution
                </Link>
                <Link to="/Multimodal-Transportation">
                  Multimodal Transportation
                </Link>
                <Link to="/Control-Process-Tower">Control Process Tower</Link>
                <Link to="/Qc-Platform">QC Platform</Link>
                <Link to="#/Buyers-MultiCustomer">
                  Buyers & Multi Customer Consolidation
                </Link>
              </nav>
            </div>
            <div className="col-sm-2 wow">
              <h4>MAIN LINKS</h4>
              <nav>
                <Link to="/">Home</Link>
                <Link to="/About-us">About us</Link>
                <Link to="/Management">Management</Link>
                <Link to="/History">History</Link>
                <Link to="/Carrer">Career</Link>
              </nav>
            </div>

            <div className="col-sm-2 wow">
              <h4>Connect with us</h4>
              <div className="footer-icons">
                <Link
                  to="https://www.facebook.com/"
                  target="_blank"
                  rel="nofollow"
                  title="Facebook">
                  <i className="fa fa-facebook-square fa-2x"></i>
                </Link>

                <Link
                  to="https://twitter.com/"
                  target="_blank"
                  rel="nofollow"
                  title="Twitter">
                  <i className="fa fa-twitter-square fa-2x"></i>
                </Link>
                <Link
                  to="https://www.linkedin.com/"
                  target="_blank"
                  rel="nofollow"
                  title="Linkedin">
                  <i className="fa fa-linkedin-square fa-2x"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="copy text-right">
            <Link id="to-top" to="#this-is-top">
              <i className="fa fa-chevron-up"></i>
            </Link>

            <div style={{ textIndent: "65px" }}>
              Copyright &copy; 2021
              <Link
                className="site-link"
                to="home.html"
                title="PartexAeromarine Logistics Ltd"
                rel="home">
                PartexAeromarine Logistics Ltd
              </Link>
              . All rights reserved.
              {/*Crafted by<Link  to="http://www.bysontech.com/home/?from=partex_aeromarine" rel="external" target="_blank"   title="Byson Technologies">BYSON</Link> */}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
