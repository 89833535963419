import React, { useState, useEffect } from "react";
import Booklet from "booklet-react-component";

const Ebrochure = () => {
  useEffect(() => {
    document.title = "Ebrochure | PartexAeromarine Logistics Ltd";
  }, []);
  const [Page, setPage] = useState(1);
  const [Move, setMove] = useState(1);

  var index = 0;
  const genNextPages = () => {
    index = index + 2;
    setPage(index);
  };
  const genPrevPages = () => {
    index = index - 2;
    setPage(index);
  };

  var flip = 0;
  const flipPageIndexForward = () => {
    flip = flip + 1;
    setMove(flip);
  };
  const flipPageIndexBack = () => {
    flip = flip - 1;
    setMove(flip);
  };
  return (
    <div>
      <div
        style={{ backgroundColor: "#001122" }}
        class="paralax bg-image page-title">
        <div class="container-fluid">
          <h1 class="title">E-Brochure</h1>
          <span class="subtitle"></span>
        </div>
      </div>
      <div id="content" class="content">
        <main class="main-content">
          <div class="container-fluid">
            <div class="col-xs-12 col-sm-12 col-md-12">
              <div class="breadcrumbsBox ">
                <a href="../home/index.html">
                  <i class="fa fa-home fa-lg"></i>
                </a>
                &nbsp;&nbsp;/&nbsp;&nbsp; E-Brochure{" "}
              </div>
            </div>
            <div class="col-xs-12  col-sm-12 col-md-12 ">
              <div class="vc_row wpb_row vc_row-fluid vc_pixrow-no-padding">
                <div class="wpb_column vc_column_container vc_col-sm-12">
                  <div class="vc_column-inner ">
                    <div class="wpb_wrapper">
                      <div class="wpb_text_column wpb_content_element ">
                        <div class="wpb_wrapper"></div>
                      </div>

                      <div>
                        <Booklet
                          flipPageIndex={Move}
                          index={Page}
                          nextPage={genNextPages}
                          prevPage={genPrevPages}
                          pages={[
                            <div>
                              {" "}
                              <img src="img_upload/image_2016_10_01_01_42_14_1.png" />
                            </div>,
                            <div>
                              {" "}
                              <img src="img_upload/image_2016_10_01_01_42_14_2.png" />
                            </div>,
                            <div>
                              {" "}
                              <img src="img_upload/image_2016_10_01_01_42_14_3.png" />
                            </div>,
                            <div>
                              {" "}
                              <img src="img_upload/image_2016_10_01_01_42_19_4.png" />
                            </div>,
                            <div>
                              {" "}
                              <img src="img_upload/image_2016_10_01_01_43_10_3_277x135.jpg" />
                            </div>,
                            <div>
                              {" "}
                              <img src="img_upload/image_2016_10_01_01_44_19_zz.jpg" />
                            </div>,
                          ]}
                        />

                        <div
                          className="button-container"
                          style={{ position: "absolute" }}>
                          <button onClick={flipPageIndexForward}>
                            Next Pages
                          </button>
                          <button onClick={flipPageIndexBack}>
                            Prev Pages
                          </button>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Ebrochure;
