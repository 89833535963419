import React, { useEffect } from "react";

import { Link } from "react-router-dom";

const SeaFreight = () => {
  useEffect(() => {
    document.title = "Sea Freight | PartexAeromarine Logistics Ltd";
  }, []);
  return (
    <div>
      <div
        style={{
          backgroundImage:
            "url(img_upload/page_2016_09_28_06_41_19_page_2016_07_29_12_43_34_seabg.jpg)",
          backgroundColor: "#001122",
        }}
        className="paralax bg-image page-title">
        <div className="container-fluid">
          <h1 className="title">Sea Freight</h1>
          <span className="subtitle"></span>
        </div>
      </div>
      <div id="content" className="content">
        <main className="main-content">
          <div className="container">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="breadcrumbsBox ">
                <Link to="/">
                  <i className="fa fa-home fa-lg"></i>
                </Link>
                &nbsp;&nbsp;/&nbsp;&nbsp; Services &nbsp;&nbsp;/&nbsp;&nbsp;
                Freight Forwarding &nbsp;&nbsp;/&nbsp;&nbsp; Sea Freight{" "}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-3">
              <aside className="sidebar">
                <div
                  className="widget widget_services_category block_content"
                  id="pix-services-widget-2">
                  <ul className="styled clearfix">
                    <li className=" ">
                      <Link className="">Freight Forwarding</Link>
                    </li>
                    <li className="active_sub li_sidebar_small">
                      <Link
                        to="/Sea-Freight-Services"
                        className="a_sidebar_small">
                        Sea Freight Services
                      </Link>
                    </li>
                    <li className="li_sidebar_small">
                      <Link
                        to="/Air-Freight-Services"
                        className="a_sidebar_small">
                        Air Freight
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Sea-Air" className="a_sidebar_small">
                        Sea Air
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link
                        to="/Multimodal-Transportation"
                        className="a_sidebar_small">
                        Multimodal Transportation
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Customs-Brokerage" className="a_sidebar_small">
                        Customs Brokerage
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Clearence" className="a_sidebar_small">
                        Clearance
                      </Link>
                    </li>
                    <li className=" " style={{ marginTop: "40px" }}>
                      <Link className="">Supply Chain Solutions</Link>
                    </li>
                    <li className="li_sidebar_small">
                      <Link
                        to="/Buyers-MultiCustomer"
                        className="a_sidebar_small">
                        Buyers & Multi Customer Consolidation
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link
                        to="/Wearhouse-Distribution"
                        className="a_sidebar_small">
                        Warehousing & Distribution
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link
                        to="/Control-Process-Tower"
                        className="a_sidebar_small">
                        Control Process Tower
                      </Link>
                    </li>
                    <li className=" " style={{ marginTop: "40px" }}>
                      <Link className="">Dedicated Offers</Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Project-Cargo" className="a_sidebar_small">
                        Project Cargo
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Special-Cargo" className="a_sidebar_small">
                        Special Cargo
                      </Link>
                    </li>
                    <li className=" li_sidebar_small">
                      <Link to="/Qc-Platform" className="a_sidebar_small">
                        QC Platform
                      </Link>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>

            <div className="col-xs-12  col-sm-12 col-md-9 ">
              <div className="vc_row wpb_row vc_row-fluid vc_pixrow-no-padding">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner ">
                    <div className="wpb_wrapper">
                      <div className="wpb_single_image wpb_content_element vc_align_left">
                        <figure className="wpb_wrapper vc_figure">
                          <div className="vc_single_image-wrapper   vc_box_border_grey">
                            <img
                              width="100%"
                              alt=""
                              className="vc_single_image-img attachment-full"
                              src="img_upload/page_2016_09_28_06_41_19_page_2016_07_29_12_47_20_sea1.jpg"
                            />
                          </div>
                        </figure>
                      </div>
                      <br />

                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <p style={{ textAlign: "justify" }}>
                            We cover the entire demesne of ocean products, in
                            addition to standard products like FCL &amp; LCL we
                            offer:
                          </p>
                          <ol>
                            <li style={{ textAlign: "justify" }}>
                              <strong>Buyers Consolidation</strong> with our
                              presence in some of the largest sourcing markets
                              in the world; we have the expertise of working
                              with multiple suppliers while managing inventories
                              for the end customers. Our in house team works
                              actively with all nominated parties to ensure that
                              inventories are managed and delivery deadlines
                              adhered to.
                            </li>
                            <li style={{ textAlign: "justify" }}>
                              <strong>MCC</strong>: Multi Customer Consolidation
                              in our ability to adapt to changing trends and
                              requirements, we understand that customers need to
                              control cost and sources from the most economical
                              locations. Our MCC expertise allows us to
                              consolidates your goods from multiple origins,
                              process them and prepare them for delivery as you
                              would present to your end customers door.
                            </li>
                            <li style={{ textAlign: "justify" }}>
                              <strong>Transshipment</strong>: We presence in
                              strategic hugs across Asia, Africa, Middle East
                              and Europe has led to a proven expertise in
                              transshipments and seamless movement of all cargo.
                              At the same time we are actively looking to
                              improve our systems to be able to better manage
                              regulatory frameworks and requirements.
                            </li>
                            <li style={{ textAlign: "justify" }}>
                              <strong>Sea Air Services</strong>:Sea Air is our
                              exclusive multimodal solution using both the
                              benefits of Air and Ocean. We offer a streamlined
                              solution with a simple documentation procedure
                              allowing for customers to save cost while still
                              maintain level of service at the origins and hubs,
                              PAM is working to further enhance this product and
                              is the process and developing new hubs and
                              covering more destinations thereby expanding this
                              solution towards our valued customers in all
                              possible manner.&nbsp;&nbsp; &nbsp;
                            </li>
                            <li style={{ textAlign: "justify" }}>
                              <strong>Others</strong>:
                              <ul>
                                <li>
                                  Easy access to shipping lines with surrender
                                  facilities.
                                </li>
                                <li>
                                  A global network to cover all carriers
                                  seaports of call and beyond.
                                </li>
                                <li>
                                  Extensive relationships with the world leading
                                  ocean freight carrier community to access
                                  routes and pricing flexibility.
                                </li>
                              </ul>
                            </li>
                          </ol>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SeaFreight;
